import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  const notFoundAnim = useRef()
  useEffect(() => {
    if (window.lottie) {
      window.lottie.loadAnimation({
        container: notFoundAnim.current,
        path: "/lottie/not-found.json",
        renderer: "svg",
      })
    }
  }, [])
  return (
    <Layout>
      <Seo title="404: Not found" />
      <div className="not-found-main">
        <div className="not-found-anim" ref={notFoundAnim} />
        <div className="container">
          <div className="flex-row">
            <div className="col col-6">
              <h1>404</h1>
              <p>Oops! we couldn’t find the page you’re looking for</p>
              <Link to="/" className="button-arrow">
                Go Home{" "}
                <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
                  <path
                    fill="#EA502A"
                    d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default NotFoundPage
